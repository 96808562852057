export default {
  account: null,
  loading: null,
  mainContract: null,
  signer: null,
  userToken: null,
  walletType: null,
  snsMap: {},
  detail: null,
  cache: null,
  currentpath: [],
  proposalCategories: [],
  rpc: "",
  currentSeason: "",
  metaforoToken: null,
  deschoolToken: null,
};
